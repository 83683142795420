import { css } from "goober";

export const ContainerCSS = () => css`
  --item-width: calc(var(--sp-10) * 5);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--item-width), 1fr));
  grid-template-rows: auto;
  grid-gap: var(--sp-4);
`;

export const LoadingCSS = () => css`
  margin: auto;
  .img-container {
    height: calc(var(--sp-10) * 5);
    width: 100%;
    cursor: default;
    margin: auto;
    border-radius: var(--br-lg);
    background: var(--shade8);
    overflow: hidden;
  }
  .reaction {
    width: var(--sp-16);
    height: var(--sp-4);
    background: var(--shade8);
    overflow: hidden;
    border-radius: var(--br);
  }
`;

export const LockerEntryCss = () => css`
  .img-container {
    height: calc(var(--sp-10) * 5);
    width: 100%;
    margin: auto;
    background: radial-gradient(#003659ff, #00baceff);
    display: flex;
    img.offer-image {
      height: var(--sp-32);
      width: var(--sp-32);
      align-self: center;
    }
  }
`;
