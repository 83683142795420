import type { PlayerMatchBE } from "@/game-fortnite/models/match-player.mjs";
import type { MatchQuery } from "@/game-fortnite/models/match-query.mjs";

const RegExpHist = /Hist/;

type FilterPropsByKey<T, Key extends string> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [Property in keyof T as Property extends `${infer Prefix}${Key}${infer Suffix}`
    ? Property
    : never]: Array<number>;
};
type Histogram = FilterPropsByKey<PlayerMatchBE, "Hist">;

export default function getHistogram(
  matches: {
    [gameId: string]: MatchQuery;
  },
  profileId: string,
): Histogram {
  const result = Object.create(null);
  for (const gameId in matches) {
    const match: MatchQuery = matches[gameId];
    if (!Array.isArray(match?.playerMatches)) continue;
    const player = match.playerMatches.find(
      (i) => i.player.accountId === profileId,
    );
    if (!player) continue;
    for (const key in player) {
      if (!RegExpHist.test(key)) continue;
      result[key] = (result[key] ?? []).concat([player[key]]);
    }
  }
  return result;
}
