import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { classNames } from "@/util/class-names.mjs";

const Container = styled("span")`
  color: var(--shade2);

  &[data-placement="1"] {
    color: var(--turq);
  }
  &[data-placement="2"] {
    color: #bbd9d9;
  }
  &[data-placement="3"] {
    color: #d07745;
  }
`;
function Placement({ placement, className }) {
  const { t } = useTranslation();

  return (
    <Container
      className={classNames("type-caption--bold", className).className}
      data-placement={placement}
    >
      {t("common:stats.placement", "{{placement, ordinal}}", {
        placement,
      })}
    </Container>
  );
}

export default Placement;
