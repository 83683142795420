import React from "react";
import { useTranslation } from "react-i18next";

import {
  FORTNITE_PLATFORMS,
  FORTNITE_PLATFORMS_SYMBOLS,
} from "@/game-fortnite/constants/platforms.mjs";
import { FORTNITE_QUEUES } from "@/game-fortnite/constants/queues.mjs";
import type { ConsoleMatch } from "@/game-fortnite/models/console-matches.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import getDataTablePills from "@/shared/get-data-table-pills.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import globals from "@/util/global-whitelist.mjs";
import { calcRate } from "@/util/helpers.mjs";

function MatchTileConsole({
  profileId,
  match,
  stats,
}: {
  profileId: string;
  match: ConsoleMatch;
  stats: {
    killsPerMatch: number;
    outlivedPerMatch: number;
    scorePerMatch: number;
  };
}) {
  const { t } = useTranslation();
  // todo(@vivek): temp till backend sends the playlist as well
  // const playlist = match.gameMode.startsWith("RELOAD")
  //   ? FORTNITE_PLAYLISTS[FORTNITE_PLAYLISTS_SYMBOLS.reload]
  //   : FORTNITE_PLAYLISTS[FORTNITE_PLAYLISTS_SYMBOLS.rankedBattleRoyale];
  const queueSymbol = Object.getOwnPropertySymbols(FORTNITE_QUEUES).find(
    (q) => FORTNITE_QUEUES[q].backendKey === match.gameMode,
  );
  const queue = FORTNITE_QUEUES[queueSymbol];
  const platform = FORTNITE_PLATFORMS[FORTNITE_PLATFORMS_SYMBOLS.console];
  const isWin = match.matchCount === 1 ? match.outlived >= 85 : false;
  const kpm = calcRate(match.kills, match.matchCount);
  const opm = calcRate(match.outlived, match.matchCount);
  const spm = calcRate(match.score, match.matchCount);
  return (
    <MatchTileExpand
      image={{
        src: Static.getProfileImage(match.id),
        alt: match.id,
        style: {
          scale: "1.2",
          translate: "0% 12%",
        },
      }}
      isWin={isWin}
      titles={[
        match.matchCount === 1
          ? {
              text: t("common:stats.placement", "{{placement, ordinal}}", {
                placement: 100 - match.outlived,
              }),
              bold: true,
              color: isWin ? "var(--turq)" : "var(--red)",
            }
          : {
              text: t(
                "fortnite:stats.matchCount",
                "{{count, number}} Matches",
                {
                  count: match.matchCount,
                },
              ),
              bold: true,
            },
        queue
          ? {
              text: queue.t,
              bold: false,
            }
          : null,
        // {
        //   text: playlist.t,
        //   bold: false,
        // },
        {
          text: platform.t,
          iconLeft: <platform.icon />,
        },
      ].filter(Boolean)}
      tabDefault={""}
      tabsToElement={{}}
      tabsLabel={[]}
      tabArgs={{}}
      urlWeb={`${globals.location.origin}/fortnite/profile/${profileId}?platform=${FORTNITE_PLATFORMS[FORTNITE_PLATFORMS_SYMBOLS.console].key}`}
      urlCanonical={`/fortnite/profile/${profileId}?platform=${FORTNITE_PLATFORMS[FORTNITE_PLATFORMS_SYMBOLS.console].key}`}
      stats={[
        {
          top: (
            <span>
              {t("common:stats.killsCount", "{{count, number}} Kills", {
                count: match.kills,
              })}
            </span>
          ),
          pill: getDataTablePills(kpm, [kpm, stats.killsPerMatch]),
          bottom: t("common:stats.kills", "Kills"),
        },
        {
          top: (
            <span>
              {t("fortnite:stats.outlivedCount", "{{count, number}} Outlived", {
                count: match.outlived,
              })}
            </span>
          ),
          pill: getDataTablePills(opm, [opm, stats.outlivedPerMatch]),
          bottom: t("fortnite:stats.outlived", "Outlived"),
        },
        {
          top: (
            <span>
              {t("fortnite:stats.scoreCount", "{{count, number}} Score", {
                count: match.score,
              })}
            </span>
          ),
          pill: getDataTablePills(spm, [spm, stats.scorePerMatch]),
          bottom: t("fortnite:stats.score", "Score"),
        },
      ]}
      matchDate={match.insertedAt}
    />
  );
}

export default MatchTileConsole;
