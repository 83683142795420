import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox";
import { ItemBox } from "@/game-fortnite/components/ItemBox";
import type { PlayerWeaponStats } from "@/game-fortnite/models/player-weapon-stats.mjs";
import {
  getParams,
  getProfilePageParamsStr,
} from "@/game-fortnite/utils/get-params.mjs";
import getWeaponKey from "@/game-fortnite/utils/get-weapon-key.mjs";
import useWeaponsFromItems from "@/game-fortnite/utils/use-weapons-from-items.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function ProfileTopWeapons({ profileId }) {
  const { t } = useTranslation();
  const state = useSnapshot(readState);

  const { searchParams } = useRoute();
  const { season } = getParams(searchParams, {
    season: state.fortnite.seasons[0]?.id,
  });
  const key = getProfilePageParamsStr(searchParams);
  const weapons = useWeaponsFromItems();
  // @ts-ignore
  const stats: PlayerWeaponStats | Error | undefined =
    state.fortnite.playerWeaponStats[profileId]?.[season]?.[key];

  const list = useMemo(() => {
    if (!stats || stats instanceof Error) return [];
    return stats
      .filter((a) => weapons[a.weapon.key.toLowerCase()])
      .sort((a, b) => b.kills - a.kills);
  }, [stats, weapons]);

  if (!stats || stats instanceof Error || stats.length === 0) return null;

  return (
    <ListItemsTruncated
      title={t("fortnite:navigation.weapons", "Weapons")}
      list={list}
      itemLeftContent={(item) => {
        const weapon = weapons[item.weapon.key.toLowerCase()];
        if (!weapon?.rarity) return null;
        return (
          <>
            <ItemBox
              id={weapon.id}
              name={weapon.displayName}
              rarity={weapon.rarity.toUpperCase() as Rarity}
              href={`/fortnite/database/weapon/${getWeaponKey(
                weapon.displayName,
              )}`}
              src={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
            />
            <p className="type-callout--bold shade0">{weapon.displayName}</p>
          </>
        );
      }}
      itemRightContent={(item) => {
        return (
          <p className="type-caption shade0">
            {t("fortnite:stats.countElims", "{{count, number}} Elims", {
              count: item.kills,
            })}
          </p>
        );
      }}
      itemLinkFormat={() => null}
      headerControls={undefined}
      showMoreOverrideFn={undefined}
      className={undefined}
    />
  );
}

export default ProfileTopWeapons;
