import { calcHeadshotPercent } from "@/shared-fps/utils.mjs";

function calcHeadshotPercentWithLimits(headshots, bodyshots) {
  return Math.min(
    100,
    calcHeadshotPercent(
      {
        headshots: headshots,
        bodyshots: bodyshots,
        legshots: 0,
        chestshots: 0,
        stomachshots: 0,
      },
      false,
      false,
    ),
  );
}

export default calcHeadshotPercentWithLimits;
