import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FORTNITE_QUEUES } from "@/game-fortnite/constants/queues.mjs";
import type { ConsoleMatch } from "@/game-fortnite/models/console-matches.mjs";
import { getWinRateColor } from "@/game-fortnite/utils/get-win-rate-color.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { calcRate, displayRate } from "@/util/helpers.mjs";

type AggItem = {
  queue: (typeof FORTNITE_QUEUES)[symbol];
  kills: number;
  wins: number;
  matches: number;
  top1: number;
  top10: number;
  top15: number;
  top25: number;
  singleMatches: number;
};

function ProfileConsoleStats({ matches }: { matches: ConsoleMatch[] }) {
  const { t } = useTranslation();
  const list = useMemo(() => {
    const items: Record<string, AggItem> = matches.reduce((acc, match) => {
      const queueSymbol = Object.getOwnPropertySymbols(FORTNITE_QUEUES).find(
        (q) => FORTNITE_QUEUES[q].backendKey === match.gameMode,
      );
      const queue = FORTNITE_QUEUES[queueSymbol];
      if (!acc[queue.key])
        acc[queue.key] = {
          queue,
          kills: 0,
          wins: 0,
          matches: 0,
          top1: 0,
          top10: 0,
          top15: 0,
          top25: 0,
          singleMatches: 0,
        };
      acc[queue.key].kills += match.kills;
      acc[queue.key].matches += match.matchCount;
      if (match.matchCount === 1) {
        acc[queue.key].singleMatches += 1;
        if (match.outlived === 99) {
          acc[queue.key].top1++;
          acc[queue.key].wins++;
        } else if (match.outlived <= 90) acc[queue.key].top10++;
        else if (match.outlived <= 85) acc[queue.key].top15++;
        else if (match.outlived <= 75) acc[queue.key].top25++;
      }
      return acc;
    }, {});
    return Object.values(items).sort((a, b) => b.matches - a.matches);
  }, [matches]);

  return (
    <ListItemsTruncated
      title={t("common:stats", "Stats")}
      list={list}
      itemLeftContent={(item) => {
        return (
          <p className="type-callout--bold shade0">{t(...item.queue.t)}</p>
        );
      }}
      itemRightContent={(item) => {
        const winRate = calcRate(item.wins, item.singleMatches);
        return (
          <>
            <p
              className="type-caption shade0"
              style={{
                color:
                  item.singleMatches > 0 ? getWinRateColor(winRate) : undefined,
              }}
            >
              {displayRate(item.wins, item.singleMatches)}{" "}
              {t("common:stats.winRate", "Win Rate")}
            </p>
            <p className="type-caption shade0">
              {t("fortnite:stats.countElims", "{{count, number}} Elims", {
                count: item.kills,
              })}
            </p>
          </>
        );
      }}
      itemLinkFormat={() => null}
      headerControls={undefined}
      showMoreOverrideFn={undefined}
      className={undefined}
    />
  );
}

export default ProfileConsoleStats;
