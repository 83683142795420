import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { FORTNITE_PLAYLISTS } from "@/game-fortnite/constants/playlists.mjs";
import { FORTNITE_QUEUES } from "@/game-fortnite/constants/queues.mjs";
import type { MatchCore } from "@/game-fortnite/models/game-events.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import Mouse from "@/inline-assets/mouse.svg";
import LiveTile from "@/shared/LiveTile.jsx";
import { MatchTile as SharedMatchTile } from "@/shared/Profile.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const FortniteLiveTile = () => {
  const { t } = useTranslation();
  const state = useSnapshot(readState);

  const game: MatchCore | null = state.fortnite.liveGame;

  if (!game) return null;

  const gameMode = game?.mode ?? game?.gameMode;
  const mode = Object.getOwnPropertySymbols(FORTNITE_PLAYLISTS).find((s) =>
    FORTNITE_PLAYLISTS[s]?.internal?.includes(gameMode),
  );
  const queue = Object.getOwnPropertySymbols(FORTNITE_QUEUES).find((s) =>
    FORTNITE_QUEUES[s]?.internal?.includes(gameMode),
  );

  return (
    <SharedMatchTile
      // no height props in MatchTile
      match={game}
      matchRoute={`/fortnite/in-game`}
      isLive
    >
      <LiveTile
        title={t("common:liveGame", "Live Game")}
        queueType={
          <>
            <Mouse />
            <span>
              {mode ? t(...FORTNITE_PLAYLISTS[mode].t) : ""}{" "}
              {queue ? t(...FORTNITE_QUEUES[queue].t) : ""}
            </span>
          </>
        }
        startTime={game.startTime ?? game.startedAt}
        myTeam={undefined}
        otherTeam={undefined}
        image={Static.getProfileImage(game.gameId)}
      />
    </SharedMatchTile>
  );
};

export default FortniteLiveTile;
