import rangeBucket from "@/util/range-bucket.mjs";

const winrateColorRange = rangeBucket([
  [0, "var(--perf-neg3)"],
  [47, "var(--perf-neg2)"],
  [48, "var(--perf-neg1)"],
  [50, "var(--perf-neutral)"],
  [52, "var(--perf-pos1)"],
  [53, "var(--perf-pos2)"],
  [54, "var(--perf-pos3)"],
]);

export const getWinRateColor = (percent) => winrateColorRange[percent];
