import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button";
import { TextInput } from "clutch/src/TextInput/TextInput";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { MIN_STRING_DISTANCE } from "@/app/constants.mjs";
import DownloadButton from "@/app/DownloadButtonWrapper.jsx";
import Reactions from "@/game-fortnite/components/Reactions";
import {
  SelectItemCategory,
  SelectRarity,
} from "@/game-fortnite/components/Selects";
import {
  FiltersContainer,
  ItemShopEntryCss,
  PageHeaderCSS,
} from "@/game-fortnite/components/Shop.style.jsx";
import {
  ITEM_CATEGORIES,
  ITEM_CATEGORIES_SYMBOLS,
} from "@/game-fortnite/constants/itemCategories.mjs";
import Rarities, {
  RARITY_SYMBOLS,
} from "@/game-fortnite/constants/rarities.mjs";
import {
  ContainerCSS,
  LoadingCSS,
  LockerEntryCss,
} from "@/game-fortnite/Locker.style.jsx";
import type { Item } from "@/game-fortnite/models/items.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import ContentContainer from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { classNames } from "@/util/class-names.mjs";
import isMobile from "@/util/is-mobile.mjs";
import { useIsLoaded, useQuery } from "@/util/router-hooks.mjs";
import stringCompare from "@/util/string-compare.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TITLE = ["fortnite:meta.locker.title", "Fortnite Locker"];

const Container = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);
`;

const Filters = ({
  searchText,
  setSearch,
  rarityFilter,
  setRarityFilter,
  category,
  setCategory,
}: {
  searchText: string;
  setSearch: (v: string) => void;
  rarityFilter: string;
  setRarityFilter: (v: string) => void;
  category: string;
  setCategory: (v: string) => void;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (EVENTS.FORTNITE_READ_PLAYER_COSMETICS) {
      blitzMessage(EVENTS.FORTNITE_READ_PLAYER_COSMETICS);
    }
  }, []);

  return (
    <div
      className={
        classNames("flex wrap gap-2 align-center", FiltersContainer()).className
      }
    >
      <div className="flex row gap-2">
        <TextInput
          defaultValue={searchText}
          placeholder={t("common:search.search", "Search")}
          onValueChange={setSearch}
          Icon={SearchIcon}
        />
        <SelectRarity
          rarityFilter={rarityFilter}
          setRarityFilter={setRarityFilter}
        />
      </div>
      <SelectItemCategory
        category={category}
        setCategory={setCategory}
        categories={ITEM_CATEGORIES}
      />
    </div>
  );
};

function LockerItem({ item }: { item: Item }) {
  return (
    <div
      className={
        classNames(
          "flex column gap-2",
          ItemShopEntryCss("", ""),
          LockerEntryCss(),
        ).className
      }
      id={item.id}
    >
      <div className="img-container">
        <img
          width={40}
          height={40}
          src={`${appURLs.CDN}/fortnite/images/${item.icon}.webp`}
          className="offer-image"
          alt={item.displayName}
          loading="lazy"
        />
        <div className="item-details">
          <p className="type-title--bold item-title">{item.displayName}</p>
        </div>
      </div>
      <Reactions id={item.id} className="reactions" />
    </div>
  );
}

function Locker({ profileId }) {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const lockerItems = state.fortnite.locker[profileId];
  const allItems = state.fortnite.items;
  const isLoaded = useIsLoaded();

  const [searchText, setSearch] = useState("");
  const [category, setCategory] = useQuery<string>(
    "category",
    ITEM_CATEGORIES[ITEM_CATEGORIES_SYMBOLS.fortniteAllItems].key,
  );
  const [rarityFilter, setRarityFilter] = useQuery<string>(
    "rarity",
    Rarities[RARITY_SYMBOLS.fortniteAllRarities].key,
  );
  const selectedRarity = useMemo(() => {
    const raritySymbol = Object.getOwnPropertySymbols(Rarities).find(
      (r) => Rarities[r].key === rarityFilter,
    );
    return raritySymbol ? Rarities[raritySymbol] : undefined;
  }, [rarityFilter]);

  const items = useMemo(() => {
    if (!lockerItems || lockerItems instanceof Error) return [];
    return lockerItems
      .filter((itemId) => {
        if (!allItems[itemId]) return false;
        const item = allItems[itemId];
        return searchText
          ? stringCompare(searchText, item.displayName) > MIN_STRING_DISTANCE
          : true;
      })
      .filter((itemId) =>
        category &&
        category !==
          ITEM_CATEGORIES[ITEM_CATEGORIES_SYMBOLS.fortniteAllItems].key
          ? allItems[itemId].type.toLowerCase() === category
          : true,
      )
      .filter((itemId) =>
        selectedRarity &&
        rarityFilter !== Rarities[RARITY_SYMBOLS.fortniteAllRarities].key
          ? allItems[itemId].rarity.toLowerCase() === selectedRarity.key
          : true,
      );
  }, [
    allItems,
    searchText,
    category,
    selectedRarity,
    lockerItems,
    rarityFilter,
  ]);

  const hasNoItems = !lockerItems && isLoaded;
  const hasNoFilteredItems = isLoaded && lockerItems && items.length === 0;

  return (
    <>
      <PageHeader title={TITLE} className={PageHeaderCSS()} />
      <Container>
        <Filters
          searchText={searchText}
          setSearch={setSearch}
          category={category}
          setCategory={setCategory}
          rarityFilter={rarityFilter}
          setRarityFilter={setRarityFilter}
        />
      </Container>
      <Container>
        <div className={ContainerCSS()}>
          {!lockerItems && !isLoaded
            ? new Array(8).fill(null).map((_, index) => (
                <div
                  key={index}
                  className={
                    classNames("flex column wrap gap-2", LoadingCSS()).className
                  }
                >
                  <div className="img-container loading-row" />
                  <div className="flex row gap-2">
                    <div className="reaction loading-row" />
                    <div className="reaction loading-row" />
                    <div className="reaction loading-row" />
                  </div>
                </div>
              ))
            : null}
          {items.map((itemId) => {
            if (!allItems[itemId]) return null;
            return <LockerItem key={itemId} item={allItems[itemId] as Item} />;
          })}
        </div>
        {hasNoFilteredItems ? (
          <p className="flex column gap-2 align-center">
            {t(
              "fortnite:locker.noFilteredItemsFound",
              "No items found in your locker.",
            )}
          </p>
        ) : null}
        {hasNoItems ? (
          <p className="flex column gap-2 align-center">
            {t(
              "fortnite:locker.noItemsFound",
              "No items found in your locker. Play Fortnite with Blitz to see your locker items.",
            )}
          </p>
        ) : null}
        {!IS_APP && isMobile() && hasNoItems ? (
          <Button
            as="a"
            href="/welcome/fortnite"
            emphasis="high"
            target="_blank"
          >
            {t("common:learnMore", "Learn More")}
          </Button>
        ) : null}
        {!IS_APP && !isMobile() && hasNoItems ? (
          <DownloadButton target="_blank">
            {t("common:downloadBlitz", "Download Blitz")}
          </DownloadButton>
        ) : null}
      </Container>
    </>
  );
}

export default Locker;
