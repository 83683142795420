import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import ProfileOverview from "@/game-fortnite/components/ProfileOverview.jsx";
import ProfileWeaponsTable from "@/game-fortnite/components/ProfileWeaponsTable.jsx";
import { ProfileTabsEnum } from "@/game-fortnite/constants/searchParams.mjs";
import Locker from "@/game-fortnite/Locker.jsx";
import type { Player as ProfileType } from "@/game-fortnite/models/profile.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { UnknownPlayerHeader } from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import ProfileEmpty from "@/shared/ProfileEmpty.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Profile(props): React.JSX.Element {
  const {
    parameters: [profileId = props.profileId, tab],
    searchParams,
  } = useRoute();
  const { t } = useTranslation();
  const state = useSnapshot(readState);

  const profile: ProfileType | Error | undefined =
    state.fortnite.profiles[profileId];
  const profileError = profile instanceof Error;
  const baseUrl = `/fortnite/profile/${profileId}`;

  const tabs: Tab[] = useMemo(() => {
    return [
      {
        name: t("fortnite:navigation.overview", "Overview"),
        id: "overview",
        url: `${baseUrl}?${searchParams}`,
        isNew: false,
        routeMatch: new RegExp(baseUrl + "(?!/weapons)(?!/locker)"),
      },
      {
        name: t("fortnite:navigation.weapons", "Weapons"),
        id: "weapons",
        url: `${baseUrl}/weapons?${searchParams}`,
        routeMatch: /weapons/,
        isNew: false,
      },
      {
        name: t("fortnite:navigation.locker", "Locker"),
        id: "weapons",
        url: `${baseUrl}/locker?${searchParams}`,
        routeMatch: /locker/,
        isNew: true,
      },
    ];
    // searchParams object doesn't change. Only its values change. So have to compare it as a string
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUrl, searchParams.toString(), t]);

  const SelectedTab = useMemo(() => {
    switch (tab) {
      case ProfileTabsEnum.Weapons:
        return ProfileWeaponsTable;
      case ProfileTabsEnum.Locker:
        return Locker;
      default:
        return ProfileOverview;
    }
  }, [tab]);

  const links = tabs.map((tab) => ({
    url: tab.url,
    text: tab.name,
    routeMatch: tab.routeMatch,
  }));

  return (
    <ProfileLayout>
      {profile ? (
        profileError ? (
          <>
            <UnknownPlayerHeader />
            <ProfileEmpty />
          </>
        ) : (
          <>
            <PageHeader
              image={Static.getProfileImage(profileId)}
              imageRound
              title={profile.name}
              links={links}
            />
            <SelectedTab profileId={profileId} />
          </>
        )
      ) : (
        <Container>
          <div className="relative">
            <Card loading style={{ height: "var(--sp-48)" }} />
            <div className="absolute-center">
              <BlitzLogoLoading />
            </div>
          </div>
        </Container>
      )}
    </ProfileLayout>
  );
}

export function meta([profileId]) {
  const profile: ProfileType | Error | undefined =
    readState.fortnite.profiles[profileId];
  const placeholder = "No User";
  // Error instance has a name property
  const name =
    profile instanceof Error ? placeholder : (profile?.name ?? placeholder);
  return {
    title: [
      "fn:profile",
      `{{name}}'s Match Stats – Fortnite – Blitz Fortnite`,
      { name },
    ],
    description: [
      "fn:profileDescription",
      `Match Stats for {{name}}`,
      { name },
    ],
  };
}

export default Profile;

interface Tab {
  name: string;
  id: string;
  url: string;
  isNew: boolean;
  routeMatch?: RegExp;
}
